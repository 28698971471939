.agency-show-box{
  background: @agency-show-box-bg;
  padding: 1rem;
  margin-top:4rem;
  border-radius: 0.375rem;

}

.agency-show-box-subtitle{
  font-size: 16px;
  font-weight: bold;
  margin-bottom:15px;
}

.agency-info-box{
  box-shadow: 0 5px 20px 0 rgba(40, 46, 56 , 0.1);
  overflow: hidden;
  border-radius: 0.375rem;
  max-width:400px;
}

.agency-info-box-phone{
  font-size:1.1rem;
  font-weight: bold;
  margin-top:10px;
  //  color: @brand-color2;
  text-decoration: none;
  display:inline-block;
}

.agency-show-box-content{
  margin-left:40px;
  color:@agency-show-box-color;

  .agency-show-contact-box{
    display: -webkit-box;
    display: flex;
    margin-bottom: 10px;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 600;


  }
  h1,h2,h3,h4,h5,p{
    // color: @agency-show-box-color;
  }

  a{
    color: @agency-show-box-link-color;

    text-decoration: none;
  }

  .agency-show-box-description{
    margin-top: 3rem;
  }
}

.agency-show-box-image{
  // box-shadow: 0 5px 20px 0 rgba(40, 46, 56 , 0.1);
  position:relative;
  margin-top:10px;
  display: block;

  img {
    border-radius: 10px;
  }

  //&:after{
  //  content:'';
  //  position: absolute;
  //  top:0;
  //  left:0;
  //  right:0;
  //  bottom:0;
  //  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(40, 46, 56, 0)), color-stop(51%, rgba(40, 46, 56, 0)), color-stop(72%, rgba(40, 46, 56, 0.50)));
  //  background-image: linear-gradient(180deg, rgba(40, 46, 56, 0), rgba(40, 46, 56, 0) 51%, rgba(40, 46, 56, 0.50) 72%);
  //
  //}
}


@media(max-width: @screen-md)
{
  .agency-show-box-content{
    margin-left:0;
  }
}