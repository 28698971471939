.col-city{
  min-height: 260px;
  //border-radius:@border-radius;
  position:relative;
  overflow: hidden;
  transition: all .4s ease 0s;
  background-repeat:no-repeat;
  background-size: cover;
  background-position: center center;
  border:2px solid #000;
  padding:20px;

  &:hover{
    .col-city-info{
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.65) 100%);
    }
  }
  .col-city-info{
    position:absolute;
    height:80px;
    bottom:0;
    left:0;

    right:0;
    padding:20px;
   // #gradient.vertical(fade(@brand-color,0%), fade(@brand-color,80%));
    background: @brand-gradient;
    color: #fff;
    transition: all .4s ease 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  .col-city-title{
    font-size:24px;
    font-family: @font-title;
  }
}

@media(max-width: @screen-md)
{
  .col-city{
    min-height: 200px;
  }

}