.social-media-wrapper{
  display:flex;
  gap: 5px;
  padding-bottom:20px;

  .contact-social-item{
    display:block;
    padding:10px;
    border:@share-icon-border;
    background: #000;
    border-radius:10px;
    svg{
      width:25px;
      height:25px;
      fill: @share-icon-color;
    }
  }
}