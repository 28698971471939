body {
  -webkit-font-smoothing: antialiased;
  // font-size: 15px;
  font-size: 0.9rem;
  font-family: @font-main;
  background-color: @body-bg-color;
  line-height: 160%;
  min-height: 100%;
  color: @body-color;
}

a:hover{
  color: @brand-color2;
}

h1,h2,h3{
  font-family: @font-title;
  //font-weight: 500;
  //color: @site-footer-title-color;

}

h1{
  // font-size: 2.7rem;
  margin:30px 0;
  font-weight: 400;
}

h2{
  margin:20px 0;
  font-size: ~"calc(1.325rem + .9vw)";
  font-weight: 400;
}

h3{
  margin:20px 0;
  font-size: ~"calc(0.725rem + .9vw)";
  color: #666666;
  font-weight: 400;
}

h1.container-title,h2.container-title,h3.container-subtitle{
  position: relative;
  text-align: center;
  span{
    color:@brand-color;
  }

}
h2.container-title{
  //margin-bottom:5px;
}

h3.container-subtitle{
 // margin-top:-15px;
  font-size:18px;
  margin-bottom:90px;
  color: #000;
}
.center-title h1, .center-title h2{
  &:after{
    content:" ";
    display:none;
  }
}

.border-8{
  padding:8px !important;
}

.bignumber{
  color: #fff;
  font-size:~"calc(2.225rem + .9vw)";
  display:block;
  margin-bottom:20px;
}

.blockquote-big{
  font-size:26px;
  font-weight: bold;
  font-style: italic;
}