.agent-info-box{
  position: relative;
  .agent-info-box-content{
    position:relative;
    width:100%;
    height: 130px;
    //#gradient.vertical(fade(@brand-dark,0%), fade(@brand-dark,90%));
    background: @brand-color;

    .agent-info-box-content-inner {
      position:absolute;
      bottom:10px;
      padding:0 15px;
      .agent-info-box-title {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
      }
      .agent-info-box-subtitle{
        color: #fff;
      }

      .agent-info-box-agencyname{
        color: #fff;
      }

      ul {
        display: none;
      }
    }
  }
}

.agentslist{
  max-width:800px;
  margin:0 auto;
}
.agent-info-boxlist{
  width:100%;
  display: flex;
  background: #FAFAFA;
  margin-bottom:20px;
  position: relative;
  .module-btn{
    font-size:18px;
    padding:15px 30px;
  }
}

.agent-info-boxlist-infolines{
  display:block;
  margin-bottom:10px;
  font-size:16px;
  svg{
    margin-right:10px;
    width:20px;
    height: 20px;
  }
  a{
    color: #000;
    text-decoration:none;
  }

}

.agent-info-boxlist-image{
  max-width:180px;
  margin-right:20px;
  max-height: 310px;
}

.agent-info-boxlist-content{
  padding:20px;
  min-width: 300px;
}

.agent-info-boxlist-title{
  text-transform: uppercase;
//  font-size: 30px;
  margin-bottom:30px;
  font-size: ~"calc(.925rem + .9vw)";
}

.agent-show-social{
  svg{
    width: 1.6rem;
    height: 1.6rem;
  }
}

.agent-contact-form{
  margin-bottom:100px;
}

@media(max-width: @screen-xs-max)
{
  .agent-info-boxlist-image{
    max-width:130px;
    margin-top:20px;
  }
  .agent-info-boxlist{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .agent-info-boxlist-title{
    margin-bottom:15px;
  }
}

