#header-fixed.fixed-nav{
  background:rgba(255,255,255,.95);
  z-index:1000;
  position:fixed;
  left:0;
  top:0;
  right:0;
  padding-bottom:10px;
  min-height: 0;
  transition: all 0.5s ease;

  .site-main-nav{
    margin-top:10px;
    padding:0;
  }



  .logo-link{
    top:10px;
  }
  .logo{
    max-height: 40px;

  }

  .navbar-toggler{
    margin-top:5px !important;
  }
}

.logo-link{
  position:absolute;
  top:20px;
 // left:100px;
}
.header-wrapper{
 // background: @header-wrapper-bg;

}

header{
  background:@header-bg;
  z-index: 20;

}
.logo{
  max-height: 90px;
}

.header-wrapper{
  min-height:100px;
  transition: all 0.5s ease;
  border-bottom:solid 5px   #67B827;
}

@media(max-width: @screen-md)
{
  header{
    height: 80px;
  }
  .logo{
    max-height: 60px;
  }

  .logo-link{

    left:50px;
  }
}

