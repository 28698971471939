.red-square{
  width:280px;
 // height:280px;
  background: fade(@brand-color,80%);
  padding:70px 0 70px 40px;
  position:absolute;
  left:60px;
  top:0;
  color: #fff;
  font-size:38px;
  font-family: @font-title;
  line-height:120%;
  span{
    width: 600px;
    display: block;
  }
}

.homepage-splash .half-splash-content{
  padding-top:300px;
  padding-bottom:5px;

  h2{
    font-size: ~"calc(0.9025rem + .9vw)";
  }
}

@media(max-width: @screen-md)
{
  .red-square{
    left:0;
    font-size:18px;

    span{
      width: 400px;
      display: block;
    }
  }


}