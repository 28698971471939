

.agency-info-box-super-super-short{
 // display: flex;

  .agency-info-box-super-short-image{
    display:block;
    width:60px;
    height: 60px;
    border-radius: 999px;
    overflow:hidden;
  }

  .agency-info-box-super-short-content{
    display:flex;
    align-items: center;
  }

  .agency-info-box-super-short-content-inner{
    display:flex;
  }
}


.agent-contact-wrapper{

}

.agency-info-box-super-short-title{
  margin-left:20px;
  font-size: 0.9rem;
  font-weight: bold;


}

.agency-info-super-short-phone{
  font-size:1.2rem;
  font-weight: bold;
  color: @brand-color2;
  text-decoration: none;
  margin-left:20px;
}
.agency-info-box-short{
  position: relative;
 // height: 340px;
  box-shadow: 0 5px 20px 0 rgba(40, 46, 56 , 0.1);
  overflow: hidden;
  border-radius: 10px;
  //display:flex;
  //max-width: 100%;
  width:100%;
  margin-bottom:20px;
  padding:5px;

  .agency-info-box-short-image{
    img{
      //max-width: 200px;
    }
  }


  .agency-info-box-short-content {
    position:relative;
    //position: absolute;
    //left: 0;
    //right: 0;
    //bottom: 0;
    //z-index: 1;
    //height: 60px;
    //background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(40, 46, 56, 0)), color-stop(51%, rgba(40, 46, 56, 0)), color-stop(72%, rgba(40, 46, 56, 0.50)));
    //background-image: linear-gradient(180deg, rgba(40, 46, 56, 0), rgba(40, 46, 56, 0) 51%, rgba(40, 46, 56, 0.50) 72%);
    //background: linear-gradient(180deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.65) 100%);
  }

  .agency-info-box-short-content-inner{

    padding-right: 24px;
    padding-left: 24px;
    //color: #fff;
    padding-top:5spx;

  }

  .agency-info-box-short-title{
    font-size:1.2rem;
    font-weight: bold;
    color: #000;
    margin-bottom:5px;
  }

  .agency-info-phone{
    font-size:1.0rem;
    font-weight: bold;
    margin-top:10px;
    color: @brand-color;
    text-decoration: none;
    //display:block;
  //  text-align:center;
  }

  .agency-info-email{
   // font-size:1.0rem;
  //  font-weight: bold;
    margin-top:10px;
    color: #000;
    text-decoration: none !important;
   // display:block;
   // text-align:center;
    margin-bottom:10px;

  }


}

.agency-info-contacts-buttons{
 // margin-top:5px;
  .btn{

    padding: 5px 20px;
    margin:5px 10px 0px 0;
    min-width: 180px;
  }
}

@media(max-width: @screen-sm) {
  .agentinfo-col{
    justify-content: center;
  }


  .agency-info-contacts-buttons {
    // margin-top:5px;
    .btn {
      width:100%;
    }
  }
  .agency-info-box-short {
   //   height:250px;
     // max-width:250px;
     justify-content: center;
    margin:0 auto 20px;
  }

  .property-show-contact-short-title{
   font-size:1.2rem !important;
  }

  .contact-form .btn{
    width:100%;
  }
}