.testimonial-slider-item{
  max-width:1100px;
  margin:0 auto;
  background: @testimonial-slider-item-bg;
  text-align:center;
  padding:80px 50px 50px 50px;
  margin-bottom: 30px;
  border-radius: @border-radius;
  border: 1px solid #FFFFFF;
  position: relative;
  color: @testimonial-slider-item-color;
  box-shadow: 0px 36px 14px rgba(0, 0, 0, 0.01), 0px 20px 12px rgba(0, 0, 0, 0.03), 0px 9px 9px rgba(0, 0, 0, 0.04), 0px 2px 5px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
}

.testimonial-slider-item-content{
  font-style: italic;
}

.testimonial-slider-item-author {
 // display: flex;
  margin: 20px auto -30px;
  text-align: center;
  display:inline-block;
 // background: #fff;
  border-radius: 9999px;
  //padding-right:15px;
  //padding-left:15px;
  padding:15px 25px;
  font-weight: 500;
  img {
    display: inline-block !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 9999px;
    margin:-15px 15px -15px -25px;

  }
}

.testimonial-slider-item-quote1{
  position: absolute;
  top:10px;
  left:10px;
  opacity: .1;
  svg{
    fill: @testimonial-slider-item-quote-svg-color;
    width:60px;
    height:60px;
  }
}

.testimonial-slider-item-quote2{
  position: absolute;
  bottom:10px;
  right:10px;
  transform: rotate(180deg);
  opacity: .1;
  svg{
    fill: @testimonial-slider-item-quote-svg-color;
    width:60px;
    height:60px;
  }

}