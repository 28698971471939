.property-main-gallery, .complex-main-gallery{
  //background:@brand-color;
}

#property-main-gallery-pager{
  display:none;
}

.property-main-gallery-item, .complex-main-gallery-item{

  display:flex;
  align-items: center;
  justify-content: center;

  img{
    width:auto !important;
    margin:0 auto;
    max-width: 100%;
    height: auto;
    max-height:600px;
  }
}



.owl-nav{
  position:absolute;
  top:50%;
  left:0;
  right:0;

  .owl-next{
    position:absolute;
    right:10px;
  }
  .owl-prev{
    position:absolute;
    left:10px;
  }

  .owl-next, .owl-prev{

    border-radius: 999px !important;
    border: 2px solid #fff !important;
    display: block;
    width:40px;
    height: 40px;
    line-height:40px;
    box-shadow: rgba(0,0, 0,  0.5) 0px 2px 3px;
    &:hover{
      background: fade(@brand-color, 50) !important;
    }
    span {
      font-size: 40px;
      line-height:30px;
    }

  }
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 20px;
}

@media(max-width: @screen-sm)
{
  .property-main-gallery-item, .complex-main-gallery-item{

    img{

      max-height:350px;
    }
  }

}