#complex-main-gallery{
  padding-top:150px;
}
.complex-wrapper{
  //background: fade(@brand-grey, 30%);
  h2{
    text-align:center;
    text-transform: uppercase;
    font-size: 1.4rem;
  }
}
.complex-show-title{
 // font-size:1.6rem;
  margin:15px 0;
  text-align: center;
}

.complex-show-zone{
  font-size:1rem;
  font-weight: bold;
  text-align: center;
  display:block;
}

.complex-show-description{
  margin-top:30px;
  max-width:1000px;
  text-align: center;
}

.complex-box-delimiter{
  background: #fff;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
}
.complex-properties{
  margin-top:30px;
  h2{
    text-align: center;
  }
}

.search-form-inset{
  .search-inner{
    border-radius:5px;
    background:rgba(0, 0, 0, .03);

    .form-group-picker .btn {
      background: #fff;
    }
  }

  .search-item{
    margin-right:10px;
  }



  margin-bottom:30px;
}

.search-form-inset .search-inner .form-group-picker .btn-filtering{
  background: #B40101;
  padding: 10px 24px;
  //border-style: solid;
  border-radius: 4px;
  //-webkit-transition: border-color .2s ease, color .2s ease, background-color .2s ease;
  //transition: border-color .2s ease, color .2s ease, background-color .2s ease;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}