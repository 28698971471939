.splash-kw-hero{
  position: absolute;
  bottom:5px;
  left:5px;
  background:url("../images/kw-hero.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width:626px;
  height:434px;
  max-width:100%;
}
.divider-simple{
  padding-top:90px;
  padding-bottom:90px;
}



.divider-grey{
  padding-top:90px;
  padding-bottom:90px;
  background: @brand-dark;
  color: #fff;
  h1,h2,h3{
    color: #fff;
  }
}

.divider-center{
  .container-title{
    text-align: center;

    &:after{
      display: none;
    }
  }
}

.divider-image1{
  padding-top:90px;
  padding-bottom:90px;
 // background:url("../images/gold-squares2.jpg") @brand-color;
  h1,h2,h3,h4,p{
    color: #fff;
  }

}

.divider-lightgrey {
  padding-top:90px;
  padding-bottom:90px;
  background: @brand-grey;
}


.divider-white {
  padding-top:90px;
  padding-bottom:90px;
  background: #fff;
}

.divider-black {
  padding-top:90px;
  padding-bottom:90px;
  background: #000;
  h1,h2,h3,h4,p{
    color: #fff;
  }
}

.divider-text-centered > .container{
  max-width: 800px;
}

.divider-fullscreen-margin{
  margin:0 15px;
}

.divider-fullscreen-padding{
  padding-left:15px;
  padding-right:15px;
}

.divider-fullscreen-title-margin h1, .divider-fullscreen-title-margin h2{
  margin-left:15px;
}

.dark-container{
  background: @brand-color;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;

}

.light-container{
  background: #fff;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;

}