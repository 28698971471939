.kw-numbers{
  color: #fff;
  font-size: 42px;
  text-align:center;
  margin-top:30px;
  span{
    display:block;
    font-size: 16px;
    text-align: center;
    margin-top:15px;
  }
}