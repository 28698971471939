.homepage-splash .half-splash{
  min-height: 600px;
  padding:0;

  .container{
    max-width:none;
  }
}


.half-splash{
  min-height: 300px;
  // background-color: @brand-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 100px 0;
  //overflow: hidden;

  //.container{
  //  padding:0;
  //}
  .half-splash-image{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-size: cover;
    background-position: center center;

  }
  .half-splash-overlay{

    opacity:.8;
    width:50%;
    position:absolute;
    top:0;
    bottom:0;
    left:0;



    &.half-splash-mode-right{
      left: 50%;
    }

    &.half-splash-mode-full{
      width: 100%;
    }

    &.half-splash-color-white{
      background: #fff;
      opacity:.6;
    }

    &.half-splash-color-blue{
      opacity:.6;
      background: #00335A;
    }

    &.half-splash-color-green{
      background: #49cf47;
    }

    &.half-splash-color-orange{
      background: #FF8000;
    }

    &.half-splash-color-black{
      background: #000;
      opacity:.2;
    }

    &.half-splash-color-black2{
      background: #000;
      opacity:.55;
    }

    &.half-splash-color-blackdark{
      background: #000;
      opacity:.9;
    }

    &.half-splash-color-reddark{
      background: #B40101;
      opacity:.9;
    }

    &.half-splash-color-grey{
      background: #757584;
      opacity:.6;
    }

    &.half-splash-color-gradient{
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.65) 100%);
      opacity:.7;
    }


    //opacity: .2;
  }
  .slider-gradient{
    position: absolute;
    bottom:0;
    width: 100%;
    //height: 250px;
    height: 40%;
    // #gradient > .vertical(rgba(0,0,0,0), rgba(0,0,0,.7));

  }





  .half-splash-content{
    display: block;
    position:relative;
    p, h1,h2,h3,h4{
      color: #fff;
    }
  }
}

.half-splash label{
  color: #fff;
}


@media(max-width: @screen-md)
{
  .homepage-splash .half-splash{
    min-height: auto;
    padding:0 0 100px 0;
  }


}