.same-size .sortable-container{
  display:flex;
  flex-grow:1;
}

.same-size .row > div{
  display:flex;
}

.text-icon{
  position:relative;
  padding: 40px;
  display: flex;
  align-items: stretch;
  color: #fff;
  flex-grow:1;
  h1,h2,h3,h4{
    color: @icontext-color-title !important;
    font-size:24px;
    margin-top:5px;
    padding-top:0px;
    margin-bottom:5px;
  }

  &:hover{
    opacity:.9;
  }

  a{
    font-size: 1rem;
    font-weight: bold;
    color:@icontext-color-link;
    text-decoration:none;
  }


  .text-icon-icon{
    border: 2px solid #fff;
    display: inline-flex;
    width:80px;
    height: 80px;
    border-radius: 9999px;
    justify-content: center;
    align-content: center;
    margin-right:20px;
    flex: 0 0 80px;
    margin-top:5px;
  }

  .text-icon-number{
    font-size:60px;
    margin-top:30px;
    margin-bottom:30px;
    display:block;
  }

  .text-icon-body{
    min-height: 120px;
  }
  svg{
    width:40px;
    height: 40px;
    align-self:center;
  }
}

.text-icon.has-number{
  flex-direction: column !important;
}

.text-icon.text-icon-bg-red{
  background: #B40101;
}

.text-icon.text-icon-bg-black{
  background: #0E0E0E;
}

.text-icon.text-icon-bg-grey{
  background: #252525;
}

.text-icon.text-icon-bg-grey2{
  background: #2F2F2F;
}

.text-icon.text-icon-bg-grey3{
  background: #4c4c4c;
}

@media (max-width: @screen-lg)
{
  .text-icon{
    flex-direction: column !important;
  }
}
